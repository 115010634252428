
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">CBL Import</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body scrollable-modal">
    <div class="row g-0" style="min-width: 135px;">
        <app-step-tracker [steps]="steps" [currentStep]="currentStepIndex"></app-step-tracker>
    </div>

    <!-- This is going to need to have a fixed height with a scrollbar-->
    <div>
        <div class="row g-0" *ngIf="currentStepIndex === Step.Import">
            <p>To get started, import a .cbl file. Kavita will perform multiple checks before importing. Some steps will block moving forward due to issues with the file.</p>
            <form [formGroup]="uploadForm" enctype="multipart/form-data">
                <file-upload formControlName="files"></file-upload>
            </form>
        </div>
        
        <ng-container *ngIf="currentStepIndex === Step.Validate">
            <p>All files have been validated to see if there are any operations to do on the list. Any lists have have failed will not move to the next step. Fix the CBL files and retry.</p>
            <div class="row g-0">

                <ngb-accordion #a="ngbAccordion">
                    <ngb-panel *ngFor="let fileToProcess of filesToProcess">
                        <ng-container *ngIf="fileToProcess.validateSummary as summary">
                            <ng-template ngbPanelTitle>
                                <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                            </ng-template>

                            <ng-template ngbPanelContent>
                                <ng-container *ngIf="summary.results.length > 0; else noValidateIssues">
                                    <h5>There are issues with the CBL that will prevent an import. Correct these issues then try again.</h5>
                                    <ol class="list-group list-group-numbered list-group-flush" >
                                        <li class="list-group-item no-hover" *ngFor="let result of summary.results" 
                                            [innerHTML]="result | cblConflictReason | safeHtml">
                                        </li>
                                    </ol>
                                </ng-container>
                                <ng-template #noValidateIssues>
                                    <div class="justify-content-center col">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                              <i class="fa-solid fa-circle-check" style="font-size: 24px" aria-hidden="true"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                Looks good
                                            </div>
                                        </div>
                                        No issues found with CBL, press next.
                                    </div>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-container>
    
        <ng-container *ngIf="currentStepIndex === Step.DryRun">
            <div class="row g-0">
                <p>This is a dry run and shows what will happen if you press Next and perform the import. All Failures will not be imported.</p>

                <ngb-accordion #a="ngbAccordion">
                    <ngb-panel *ngFor="let fileToProcess of filesToProcess">
                        <ng-container *ngIf="fileToProcess.dryRunSummary as summary">
                            <ng-template ngbPanelTitle>
                                <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                            </ng-template>

                            <ng-template ngbPanelContent>
                                <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                            </ng-template>
                        </ng-container>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStepIndex === Step.Finalize">
            <div class="row g-0">
                <ngb-accordion #a="ngbAccordion">
                    <ngb-panel *ngFor="let fileToProcess of filesToProcess">
                        <ng-container *ngIf="fileToProcess.finalizeSummary as summary">
                            <ng-template ngbPanelTitle>
                                <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                            </ng-template>

                            <ng-template ngbPanelContent>
                                <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                            </ng-template>
                        </ng-container>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-container>
    </div>

    <ng-template #resultsList let-summary="summary">
        <ul class="list-group list-group-flush">
            <li class="list-group-item no-hover" *ngFor="let result of summary.results"  
                innerHTML="{{result.order + 1}}. {{result | cblConflictReason | safeHtml}}"></li>
        </ul>
    </ng-template>

    <ng-template #heading let-filename="filename" let-summary="summary">
            <ng-container *ngIf="summary.success | cblImportResult as success">
                <ng-container [ngSwitch]="summary.success">
                    <span *ngSwitchCase="CblImportResult.Success" class="badge bg-primary me-1">{{success}}</span>    
                    <span *ngSwitchCase="CblImportResult.Fail" class="badge bg-danger me-1">{{success}}</span>    
                    <span *ngSwitchCase="CblImportResult.Partial" class="badge bg-warning me-1">{{success}}</span>    
                </ng-container>
            </ng-container>
            <span>{{filename}}<span *ngIf="summary.cblName">: ({{summary.cblName}})</span></span>
    </ng-template>


</div>
<div class="modal-footer">
    <a class="btn btn-icon" href="https://wiki.kavitareader.com/en/guides/get-started-using-your-library/reading-lists#creating-a-reading-list-via-cbl" target="_blank" rel="noopener noreferrer">Help</a>
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="prevStep()" [disabled]="!canMoveToPrevStep()">Prev</button>
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!canMoveToNextStep()">{{NextButtonLabel}}</button>
</div>


