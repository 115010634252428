
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Reading List: {{readingList.title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body scrollable-modal {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? '' : 'd-flex'}}">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills"
     orientation="{{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'horizontal' : 'vertical'}}" style="min-width: 135px;">     
        <li [ngbNavItem]="TabID.General">
            <a ngbNavLink>{{TabID.General}}</a>
            <ng-template ngbNavContent>
                <form [formGroup]="reviewGroup">
                    <div class="row g-0 mb-3">
                        <div class="col-md-8 col-sm-12">
                            <label for="library-name" class="form-label">Name</label>
                            <input id="library-name" class="form-control" formControlName="title" type="text" [class.is-invalid]="reviewGroup.get('title')?.invalid && reviewGroup.get('title')?.touched">
                            <div id="inviteForm-validations" class="invalid-feedback" *ngIf="reviewGroup.dirty || reviewGroup.touched">
                                <div *ngIf="reviewGroup.get('title')?.errors?.required">
                                    This field is required
                                </div>
                                <div *ngIf="reviewGroup.get('title')?.errors?.duplicateName">
                                    Name must be unique
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="(accountService.currentUser$ | async) as user">
                            <div class="col-md-3 col-sm-12 ms-2" *ngIf="accountService.hasAdminRole(user)">
                                <div class="form-check form-switch">
                                    <input type="checkbox" id="tag-promoted" role="switch" formControlName="promoted" class="form-check-input"
                                        aria-labelledby="auto-close-label" aria-describedby="tag-promoted-help">
                                    <label class="form-check-label me-1" for="tag-promoted">Promote</label>
                                    <i class="fa fa-info-circle" aria-hidden="true" placement="left" [ngbTooltip]="promotedTooltip" role="button" tabindex="0"></i>
                                    <ng-template #promotedTooltip>Promotion means that the tag can be seen server-wide, not just for admin users. All series that have this tag will still have user-access restrictions placed on them.</ng-template>
                                    <span class="visually-hidden" id="tag-promoted-help"><ng-container [ngTemplateOutlet]="promotedTooltip"></ng-container></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row g-0 mb-3">
                        <div class="col-md-6 col-sm-12">
                            <h6 id="starting-year-header">Starting</h6>
                            <div class="col-md-6 col-sm-12" *ngIf="reviewGroup.get('startingMonth') as formControl" style="width: 90%">
                                <label for="start-month" class="form-label">Month</label>
                                <input id="start-month" class="form-control" formControlName="startingMonth" 
                                type="number" inputmode="numeric" [class.is-invalid]="formControl?.invalid && formControl?.touched"
                                aria-describedby="starting-year-header">
                                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="reviewGroup.dirty || reviewGroup.touched">
                                    <div *ngIf="formControl.errors?.min || formControl.errors?.max">
                                        Must be between 1 and 12 or blank
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12" *ngIf="reviewGroup.get('startingYear') as formControl" style="width: 90%">
                                <label for="start-year" class="form-label">Year</label>
                                <input id="start-year" class="form-control" formControlName="startingYear" type="number" inputmode="numeric"
                                [class.is-invalid]="formControl.invalid && formControl.touched"
                                aria-describedby="starting-year-header">
                                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="reviewGroup.dirty || reviewGroup.touched">
                                    <div *ngIf="formControl.errors?.min || formControl.errors?.max">
                                        Must be greater than 1000, 0 or blank
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                            <h6 id="ending-year-heading">Ending</h6>
                            <div class="col-md-6 col-sm-12" *ngIf="reviewGroup.get('endingMonth') as formControl" style="width: 90%">
                                <label for="library-name" class="form-label">Month</label>
                                <input id="library-name" class="form-control" formControlName="endingMonth" type="number" inputmode="numeric"
                                 [class.is-invalid]="formControl?.invalid && formControl?.touched"
                                 aria-describedby="ending-year-header">
                                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="reviewGroup.dirty || reviewGroup.touched">
                                    <div *ngIf="formControl.errors?.min || formControl.errors?.max">
                                        Must be between 1 and 12 or blank
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12" *ngIf="reviewGroup.get('endingYear') as formControl" style="width: 90%">
                                <label for="library-name" class="form-label">Year</label>
                                <input id="library-name" class="form-control" formControlName="endingYear" type="number" inputmode="numeric"
                                [class.is-invalid]="formControl?.invalid && formControl?.touched"
                                aria-describedby="ending-year-header">
                                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="reviewGroup.dirty || reviewGroup.touched">
                                    <div *ngIf="formControl.errors?.min || formControl.errors?.max">
                                        Must be greater than 1000, 0 or blank
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row g-0 mb-3">
                        <label for="summary" class="form-label">Summary</label>
                        <textarea id="summary" class="form-control" formControlName="summary" rows="3"></textarea>
                    </div>
                </form>
            </ng-template>
        </li>
        <li [ngbNavItem]="TabID.CoverImage">
            <a ngbNavLink>{{TabID.CoverImage}}</a>
            <ng-template ngbNavContent>
                <app-cover-image-chooser [(imageUrls)]="imageUrls" (imageSelected)="updateSelectedIndex($event)" (selectedBase64Url)="updateSelectedImage($event)" [showReset]="readingList.coverImageLocked" (resetClicked)="handleReset()"></app-cover-image-chooser>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'mt-3' : 'ms-4 flex-fill'}}"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
</div>


